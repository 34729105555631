<template>
    <div class="dashboard__block wall__item wall__notification" v-show="materials.length > 0">
        <div class="dashboard__block wall__material">
            <h3 class="wall__material-title">Изменения в базе знаний от
                <template v-if="date.toString().indexOf(String(new Date().getFullYear())) > 0">
                    {{date.split(' ')[0] |  moment('D MMMM') }}
                </template>
                <template v-else>
                    {{date.split(' ')[0] |  moment('D MMMM YYYY') }}
                </template>
            </h3>
            <div class="wall__material-item" v-for="(item, index) of posts" :item="item" :key="$uuid.v1()">
                <span class="wall__material-date">
                    {{ item.time }}
                </span>
                <span class="wall__material-post">
                    <template v-if="item.status === 'created'">
                        <template v-if="item.fio">
                            {{item.fio}} опубликовал материал
                        </template>
                        <template v-else>
                            Опубликован материал
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="item.fio">
                            {{item.fio}} отредактировал материал
                        </template>
                        <template v-else>
                            Отредактирован материал
                        </template>
                    </template>
                    <router-link :to="item.link">“{{item.title}}”</router-link>
                </span>
            </div>
            <button type="button" class="wall__material-more wall__material-more_is-closed" v-if="materials.length > 4" v-show="!showAll" @click="showAll = true">Еще изменения</button>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    export default {
        name: "Material",
        props: {
            date: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                showAll: false,
                materials: []
            }
        },
        async created() {
            await this.loadMaterials()
        },
        computed: {
            posts() {
                if (this.showAll) {
                    return this.materials
                } else {
                    return this.materials.slice(0, 4)
                }
            }
        },
        methods: {
            async loadMaterials() {
                let response;
                try {
                    var dt = new Date(this.date);
                    var requestDt = dt.toLocaleDateString('en-US');
                    const request = session.post('/api/v1/wall/materials/', {'date': requestDt});
                    
                    response = await request;
                    this.materials = response.data
                } catch (err) {

                }
            }
        }
    }
</script>

<style scoped>

</style>
